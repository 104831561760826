import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default () => {
  return (
    <Layout expand>
      <Seo title="Donate to Kyle Kelley for Santa Cruz City Schools" />
      <div
        style={{
          overflow: "hidden",
          paddingTop: "var(--size-content)",
          position: "relative",
        }}
      >
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/shrHPQl7AHboueABh?backgroundColor=green"
          frameBorder="0"
          //   onmousewheel=""
          // width="100%"
          // height="100%"
          style={{
            background: "transparent",
            // border: "1px solid #efefef",
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
          }}
        ></iframe>
      </div>
    </Layout>
  )
}
